import _ from 'lodash';
import { Container, Graphics } from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { Colors, type SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setBetAmount, setCoinAmount, setCurrency } from '../../gql/cache';
import { isScatter, normalizeCoins, showCurrency } from '../../utils';
import { TextField } from '../components/TextField';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  PAY_TABLE_HEIGHT,
  PAY_TABLE_WIDTH,
  eventManager,
  miniPayTableAdditionalTextStyle,
  miniPayTableStyle,
} from '../config';
import type { Combos, Icon, IconCombo } from '../d';

class MiniPayTable extends Container {
  private id: number;

  private currency = '';

  private betAmount: number;

  public iconId: SlotId;

  public combos: Combos;

  public rect!: Graphics;

  private wrap!: Container;

  public multipliers!: TextField;

  public additionalText!: TextField;

  constructor(id: number, icon: Icon, combos: Combos) {
    super();
    this.id = id;
    this.iconId = icon.id;
    this.visible = false;
    this.sortableChildren = true;
    this.combos = _.cloneDeep(combos)?.reverse();
    this.betAmount = setBetAmount();
    this.currency = setCurrency();
    this.initRect();
    this.addChild(this.rect);
    this.initWrap();
    this.addChild(this.wrap);
    this.initMultipliers();
    this.wrap.addChild(this.multipliers.getText());
    this.initAdditionalText();
    this.wrap.addChild(this.additionalText.getText());
    this.setPayTableData(icon.id, combos);

    eventManager.addListener(EventTypes.SHOW_PAY_TABLE, (i: number) => {
      this.showPayTable(i);
    });
    eventManager.addListener(EventTypes.DISABLE_ALL_MINI_PAY_TABLES, () => (this.visible = false));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => (this.visible = false));
    eventManager.addListener(EventTypes.UPDATE_BET, () => this.handleChangeBetAmount());
  }

  initRect(): void {
    this.rect = new Graphics();
    this.rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
    this.rect.alpha = 0.6;
    this.rect.drawRoundedRect(0, 0, PAY_TABLE_WIDTH, PAY_TABLE_HEIGHT, 0);
  }

  initWrap(): void {
    this.wrap = new Container();
  }

  setPayTableData(iconId: SlotId, combos: Combos): void {
    this.iconId = iconId;
    this.combos = combos;

    this.updatePayTableData();
  }

  initMultipliers(): void {
    this.multipliers = new TextField('', PAY_TABLE_WIDTH - 20, PAY_TABLE_HEIGHT - 20, {}, true, {
      default: {
        ...miniPayTableStyle,
        align: 'left',
        lineHeight: 50,
        fontSize: 28,
      },
      span: {
        fill: Colors.GAME_COLOR,
        lineHeight: 50,
      },
    });
    this.multipliers.text.anchor.set(0.5, 0);
  }

  private initAdditionalText(): void {
    this.additionalText = new TextField('', PAY_TABLE_WIDTH - 20, 70, miniPayTableAdditionalTextStyle);
    this.additionalText.text.anchor.set(0.5, 0);
  }

  private calcMultiplier(multiplier: number): number {
    if (isScatter(this.iconId)) {
      return normalizeCoins(setBetAmount() * multiplier);
    }
    return normalizeCoins(setCoinAmount() * multiplier);
  }

  public showPayTable(uniqueId: number): void | undefined {
    if (!this.combos?.length) return;
    if (uniqueId !== this.id) {
      this.visible = false;
      eventManager.emit(EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY, [this.id], true);
      return;
    }
    eventManager.emit(EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY, [uniqueId], this.visible);
    this.visible = !this.visible;
  }

  private getCombos(): string {
    const combos =
      this.combos?.reduce(
        (acc: string, curr: IconCombo) =>
          `${acc} ${curr.pattern} <span>${formatNumber({
            currency: this.currency,
            value: this.calcMultiplier(curr.multiplier),
            showCurrency: showCurrency(this.currency),
          })}</span>\n`,
        '',
      ) || '';

    return combos.trimEnd();
  }

  private handleChangeBetAmount(): void {
    this.updatePayTableData();
  }

  private updatePayTableData(): void {
    this.multipliers.setText(this.getCombos());
    this.additionalText.text.visible = false;
    this.additionalText.setText('');

    this.wrap.x = PAY_TABLE_WIDTH / 2;
    this.wrap.y = PAY_TABLE_HEIGHT / 2 - this.wrap.height / 2;
  }
}

export default MiniPayTable;
