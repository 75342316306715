import { BonusStatus, GameMode, UserBonus, bonusIds } from '../../global.d';
import {
  setBottomContainerTotalWin,
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFrbReferenceId,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setIsBuyFeaturePopupOpened,
  setIsFreeRoundsBonus,
  setIsMaxLevelFreeSpinsFeature,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { ISlotHistoryData } from '../../gql/d';
import { getUserBonuses, userBonusBetsGql } from '../../gql/query';
import { debugDisplay, getGameModeByBonusId, getLevelByGameMode, isFreeRoundBonus } from '../../utils';

export const checkBrokenGame = async () => {
  const activeUserBonusData = await client.query<{
    userBonuses: UserBonus[];
  }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });

  // its locally for testing.
  // ------------------------------------------------------
  // const frbBonus = {
  //   data: {
  //     userBonuses: [
  //       {
  //         id: '',
  //         isActive: false,
  //         bonusId: '7d69bc5b-4786-4521-9f53-130486fbd97f',
  //         currentRound: 0,
  //         totalRounds: 0,
  //         rounds: 4,
  //         gameMode: GameMode.REGULAR,
  //         reelSetId: '949dd59b-4b6d-4099-a00a-ae2c2861f951',
  //         totalWinAmount: 350000,
  //         betId: '',
  //         coinAmount: 1,
  //         coinValue: 1000,
  //         bonus: {
  //           id: '',
  //           slotId: '',
  //           reelSetId: '',
  //           lineSetId: '',
  //           type: '',
  //           coinAmount: 1,
  //           purchasable: false,
  //           rounds: 0,
  //           data: {},
  //         },
  //       },
  //     ],
  //   },
  // };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // const activeUserBonusData: ApolloQueryResult<{
  //   userBonuses: UserBonus[];
  // }> = JSON.parse(JSON.stringify(activeUserBonusData1));
  // ------------------------------------------------------
  // activeUserBonusData.data.userBonuses.push(frbBonus.data.userBonuses[0] as UserBonus);
  if (activeUserBonusData.data.userBonuses.length > 0) {
    const bonus = activeUserBonusData.data.userBonuses.find((e) => !isFreeRoundBonus(e.bonusId));

    const frbBonus = activeUserBonusData.data.userBonuses.find((e) => isFreeRoundBonus(e.bonusId));

    const frbTotalAmount = frbBonus?.totalWinAmount ? frbBonus?.totalWinAmount / frbBonus.coinValue : 0;

    const fsTotalAmount = bonus?.totalWinAmount ? bonus?.totalWinAmount / bonus.coinValue : 0;

    debugDisplay('bonus', bonus, 'frbBonus', frbBonus);

    if (frbBonus && (!bonus || (bonus && bonus.data.frbReferenceId))) {
      setIsFreeRoundsBonus(true);
      setFreeRoundsBonus({
        ...frbBonus,
        isActive: false,
        currentRound: 0,
      });
      if (!bonus?.data.frbReferenceId) {
        setBottomContainerTotalWin(frbTotalAmount);
        setFreeRoundsTotalWin(frbTotalAmount);
        // debugDisplay('LoadScreen setFreeRoundsTotalWin()', setFreeRoundsTotalWin());
      } else {
        setFreeRoundsTotalWin(frbTotalAmount - (fsTotalAmount || 0));
      }
    }

    if (
      (bonus && bonus.bonusId === bonusIds[GameMode.BUY_FEATURE]) ||
      (bonus && bonus.bonusId === bonusIds[GameMode.BUY_FEATURE_MAX])
    ) {
      setBrokenBuyFeature(true);
      setIsBuyFeaturePopupOpened(true);
      if (bonus.bonusId === bonusIds[GameMode.BUY_FEATURE_MAX]) {
        setIsMaxLevelFreeSpinsFeature(true);
      }
      setCurrentBonusId(activeUserBonusData.data.userBonuses[0]!.id);
      setCurrentBonus({
        ...activeUserBonusData.data.userBonuses[0]!,
        isActive: true,
      });
    }

    if (bonus) {
      setBrokenGame(true);
      setCurrentBonus({
        ...bonus,
        isActive: true,
      });

      const userBonusBetsData = await client.query<{
        bets: ISlotHistoryData;
      }>({
        query: userBonusBetsGql,

        variables: {
          input: {
            filter: {
              userBonusId: setCurrentBonus().id,
            },
          },
        },
        fetchPolicy: 'network-only',
      });

      if (getLevelByGameMode(getGameModeByBonusId(setCurrentBonus().bonusId)) === 5) {
        setCurrentBonus({
          ...setCurrentBonus(),
          currentRound: 8 - setCurrentBonus().rounds,
          rounds: 8,
        });
      } else {
        setCurrentBonus({
          ...setCurrentBonus(),
          currentRound: userBonusBetsData.data.bets.pageInfo.count,
          rounds: setCurrentBonus().rounds + userBonusBetsData.data.bets.pageInfo.count,
        });
      }
      setCurrentFreeSpinsTotalWin(fsTotalAmount);
      if (frbBonus) {
        setBottomContainerTotalWin(
          frbBonus && bonus.data.frbReferenceId ? frbTotalAmount - (fsTotalAmount || 0) : fsTotalAmount,
        );
      } else {
        setBottomContainerTotalWin(0);
      }

      if (bonus.data.frbReferenceId) {
        setFrbReferenceId(true);
      } else {
        setBottomContainerTotalWin(0);
      }

      debugDisplay(
        'LoadScreen setBottomContainerTotalWin',
        'frbBonus',
        frbBonus,
        'bonus.data.frbReferenceId',
        bonus.data.frbReferenceId,
        setBottomContainerTotalWin(),
        'frbTotalAmount',
        frbTotalAmount,
        'fsTotalAmount',
        fsTotalAmount,
      );
    }
  }
};
