export const BANNER_POSITION_X = -105;
export const MOBILE_BANNER_POSITION_X = 95;
export const BANNER_POSITION_Y = 125;
export const BANNER_WIDTH = 1300;
export const MOBILE_BANNER_WIDTH = 1100;
export const MOBILE_WIN_BANNER_WIDTH = 1150;
export const MOBILE_WIN_BANNER_X = 70;
export const BANNER_HEIGHT = 990;
export const MOBILE_BANNER_HEIGHT = 1010;
export const TEXT_DIVIDE_COLOR = 0xea00ff;
export const font = 'Arial';

export const DEFAULT_CAROUSEL_TEXT = {
  fontSize: 70,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: '500',
  align: 'center',
  fill: 'white',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
};

// export const BANNER_BACKGROUND_COLOR = 0xffffff;

export const titlePosition = 275;
export const subtitlePosition = 550;
export const additionalPosition = 240;
export const BUTTON_POSITION_Y = 600;
export const MOBILE_BUTTON_POSITION_Y = 830;
export const BUTTON_POSITION_X = 650;
export const MOBILE_BUTTON_POSITION_X = 665;
export const INIT_TEXT_POSITION_Y = 275;
export const MOBILE_INIT_TEXT_POSITION_Y = 200;
export const TEXT_POSITION_X = 650;
export const MOBILE_TEXT_POSITION_X = 665;
export const SUBTEXT_POSITION_X = 650;
export const INIT_SUBTEXT_POSITION_Y = 420;
export const MOBILE_INITSUB_TEXT_POSITION_Y = 500;
export const LEFT_CHARACTER_WIDTH = 302;
export const MOBILE_LEFT_CHARACTER_WIDTH = 380;
export const LEFT_CHARACTER_HEIGHT = 515;
export const MOBILE_LEFT_CHARACTER_HEIGHT = 540;
export const LEFT_CHARACTER_Y = 550;
export const LEFT_CHARACTER_X = 250;
export const MOBILE_LEFT_CHARACTER_X = 330;
export const RIGHT_CHARACTER_WIDTH = 350;
export const MOBILE_RIGHT_CHARACTER_WIDTH = 380;
export const RIGHT_CHARACTER_HEIGHT = 428;
export const MOBILE_RIGHT_CHARACTER_HEIGHT = 540;
export const RIGHT_CHARACTER_Y = 550;
export const RIGHT_CHARACTER_X = 1050;
export const MOBILE_RIGHT_CHARACTER_X = 960;

export enum Variables {
  FONT_FAMILY = 'NotoSans-SemiCondensedBold',
}
